import {
  Avatar,
  Box,
  Flex,
  Icon,
  Img,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Skeleton,
} from "@chakra-ui/react";
import { FC, FormEventHandler, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaUserCircle } from "react-icons/fa";
import { FiSearch } from "react-icons/fi";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useDebounce } from "use-debounce";
import Input from "../../../../components/Input/Input";
import Logo from "../../../../components/Logo/Logo";
import AuthContext from "../../../../context/AuthContext";
import { BlurContext } from "../../../../context/BlurContext";
import { SidebarContext } from "../../../../context/SidebarContext";

const Navbar: FC = () => {
  const { t } = useTranslation();
  const { user, logout, isLoading: isAuthLoading } = useContext(AuthContext);
  const { isBlur } = useContext(BlurContext);
  const { setIsExpanded, isExpanded } = useContext(SidebarContext);
  const navigate = useNavigate();
  const [query] = useSearchParams();

  const [keyword, setKeyword] = useState(query.get("search")?.toString() || "");
  const [search] = useDebounce(keyword, 500);
  const { pathname } = useLocation();

  const handleSearchSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    navigate(`/menu?search=${keyword}`);
  };

  const handleLogout = () => {
    logout();
  };

  useEffect(() => {
    if (pathname === "/menu" && (search || query.get("search"))) {
      navigate(search ? `/menu?search=${search}` : "/menu");
    }
  }, [search, navigate, pathname, query]);

  return (
    <Flex
      as="nav"
      position="fixed"
      left={isExpanded ? ["0", "265px", "300px"] : "0"}
      right={"0"}
      top={0}
      padding={[3, 4]}
      alignItems={"center"}
      justifyContent={"space-between"}
      background="white"
      filter={isBlur ? "blur(3px)" : ""}
      zIndex={99}
    >
      <Flex alignItems={"center"} gap={"24px"}>
        {!isExpanded && (
          <Flex gap={"12px"}>
            <button onClick={() => setIsExpanded(true)}>
              <Img src="/icons/bar.svg" />
            </button>
            <Logo />
          </Flex>
        )}

        <Box display={["none", "block", "block"]}>
          <form onSubmit={handleSearchSubmit}>
            <Input
              placeholder={t("Search Product")}
              height={["28px", "32px", "48px"]}
              width={["100%", "213px", "573px"]}
              accessoryLeft={<Icon as={FiSearch} />}
              value={keyword}
              onChange={(e) => setKeyword(e.currentTarget.value)}
            />
          </form>
        </Box>
      </Flex>

      <Skeleton isLoaded={!isAuthLoading}>
        <Flex alignItems={"center"} gap={["17px", "17px", "24px"]}>
          <Flex gap={"8px"}>
            <button>
              <Img src="/icons/notification.svg" />
            </button>

            <Menu>
              <MenuButton>
                <Flex>
                  {user?.avatarSrc?.xs ? (
                    <Avatar
                      src={user?.avatarSrc.xs}
                      width={[8, 10]}
                      height={[8, 10]}
                    />
                  ) : (
                    <Icon as={FaUserCircle} color="gray.400" w={12} h={12} />
                  )}
                  <Img src="/icons/arrow-down.svg" />
                </Flex>
              </MenuButton>

              <MenuList>
                <MenuItem>{t("Profile")}</MenuItem>
                <MenuDivider />
                <MenuItem onClick={handleLogout}>{t("Logout")}</MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </Flex>
      </Skeleton>
    </Flex>
  );
};

export default Navbar;

import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";

type ContextType = {
  isExpanded: boolean;
  setIsExpanded: Dispatch<SetStateAction<boolean>> | ((value: boolean) => null);
};

const defaultValue: ContextType = {
  isExpanded: false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setIsExpanded: (value: boolean) => null,
};

export const SidebarContext = createContext<ContextType>(defaultValue);

export const SidebarProvider: FC = ({ children }) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const onResize = () => {
    console.log(window.innerWidth, "isExpanded", window.innerWidth > 482);
    setIsExpanded(window.innerWidth > 482);
  };

  useEffect(() => {
    onResize();
    window.addEventListener("resize", onResize);

    return () => window.removeEventListener("resize", onResize);
  }, []);

  useEffect(() => {
    console.log({ isExpanded });
  }, [isExpanded]);

  return (
    <SidebarContext.Provider value={{ isExpanded, setIsExpanded }}>
      {children}
    </SidebarContext.Provider>
  );
};

import "./AdminLayout.css";

import { Box } from "@chakra-ui/react";
import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import Sidebar from "./components/Sidebar/Sidebar";
import Navbar from "./components/Navbar/Navbar";
import { BlurContext } from "../../context/BlurContext";
import { SidebarContext } from "../../context/SidebarContext";
import ProtectedPage from "../../middleware/ProtectedPage";

const AdminLayout: FC = () => {
  const { i18n } = useTranslation();
  const { isBlur } = useContext(BlurContext);
  const { isExpanded } = useContext(SidebarContext);

  return (
    <ProtectedPage role="admin">
      <Box dir={i18n.language === "ar" ? "rtl" : "ltr"} width={"100%"}>
        <Sidebar />
        <Navbar />

        <Box
          marginLeft={isExpanded ? ["0", "265px", "300px"] : "0"}
          marginTop={["100px", "120px"]}
          filter={isBlur ? "blur(3px)" : ""}
        >
          <Outlet />
        </Box>
      </Box>
    </ProtectedPage>
  );
};

export default AdminLayout;

import { Center, CircularProgress } from "@chakra-ui/react";
import { lazy, ReactNode, Suspense, useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { BlurProvider } from "../context/BlurContext";
import { SidebarProvider } from "../context/SidebarContext";

import AdminLayout from "../layouts/Admin/AdminLayout";
import AuthLayout from "../layouts/Common/AuthLayout";

const Login = lazy(() => import("../pages/auth/Login"));
const Home = lazy(() => import("../pages/home/Home"));
const Users = lazy(() => import("../pages/users/Users"));
const Consultation = lazy(() => import("../pages/consultation/Consultation"));
const ConsultationAdd = lazy(
  () => import("../pages/consultation/add/ConsultationAdd")
);
const ContentGallery = lazy(
  () => import("../pages/content/gallery/ContentGallery")
);
const ContentGalleryAdd = lazy(
  () => import("../pages/content/gallery/add/ContentGalleryAdd")
);
const ContentTestimony = lazy(
  () => import("../pages/content/testimonies/ContentTestimony")
);
const ContentTestimonyAdd = lazy(
  () => import("../pages/content/testimonies/add/ContentTestimonyAdd")
);
const BlogCategories = lazy(
  () => import("../pages/blog/categories/BlogCategories")
);
const BlogCategoriesAdd = lazy(
  () => import("../pages/blog/categories/add/BlogCategoryAdd")
);
const BlogPosts = lazy(() => import("../pages/blog/posts/BlogPosts"));
const BlogPostAdd = lazy(() => import("../pages/blog/posts/add/BlogPostAdd"));
const StoreCategories = lazy(
  () => import("../pages/store/categories/StoreCategories")
);
const StoreProducts = lazy(
  () => import("../pages/store/products/StoreProducts")
);
const StoreProductsAdd = lazy(
  () => import("../pages/store/products/add/StoreProductsAdd")
);
const Events = lazy(() => import("../pages/events/Events"));
const EventAdd = lazy(() => import("../pages/events/add/EventAdd"));

function AdminApp() {
  const [isLoggedIn] = useState<boolean>(false);
  const location = useLocation();
  const navigate = useNavigate();

  // should replaced using component to Protect routes
  useEffect(() => {
    if (location.pathname === "/" && isLoggedIn) {
      navigate("/auth/login");
    }
  }, [isLoggedIn, location.pathname, navigate]);

  const renderSuspense = (el: ReactNode) => {
    const fallback = (
      <Center py={[4, 20]}>
        <CircularProgress isIndeterminate color="primary.500" />
      </Center>
    );

    return <Suspense fallback={fallback}>{el}</Suspense>;
  };

  return (
    <BlurProvider>
      <SidebarProvider>
        <Routes>
          <Route path="/" element={<AuthLayout />}>
            <Route path="/auth/login" element={renderSuspense(<Login />)} />
          </Route>
          <Route path="/" element={<AdminLayout />}>
            <Route index element={renderSuspense(<Home />)} />
            <Route path="users" element={renderSuspense(<Users />)} />
            <Route
              path="consultations"
              element={renderSuspense(<Consultation />)}
            />
            <Route
              path="consultations/add"
              element={renderSuspense(<ConsultationAdd />)}
            />
            <Route
              path="consultations/:roomId/edit"
              element={renderSuspense(<ConsultationAdd />)}
            />

            <Route
              path="content/gallery"
              element={renderSuspense(<ContentGallery />)}
            />
            <Route
              path="content/gallery/add"
              element={renderSuspense(<ContentGalleryAdd />)}
            />
            <Route
              path="content/gallery/:galleryId/edit"
              element={renderSuspense(<ContentGalleryAdd />)}
            />

            <Route
              path="content/testimonies"
              element={renderSuspense(<ContentTestimony />)}
            />
            <Route
              path="content/testimonies/add"
              element={renderSuspense(<ContentTestimonyAdd />)}
            />
            <Route
              path="content/testimonies/:testimony/edit"
              element={renderSuspense(<ContentTestimonyAdd />)}
            />

            <Route
              path="blog/categories"
              element={renderSuspense(<BlogCategories />)}
            />
            <Route
              path="blog/categories/add"
              element={renderSuspense(<BlogCategoriesAdd />)}
            />
            <Route
              path="blog/categories/:categoryId/edit"
              element={renderSuspense(<BlogCategoriesAdd />)}
            />
            <Route path="blog/posts" element={renderSuspense(<BlogPosts />)} />
            <Route
              path="blog/posts/add"
              element={renderSuspense(<BlogPostAdd />)}
            />
            <Route
              path="blog/posts/:postId/edit"
              element={renderSuspense(<BlogPostAdd />)}
            />

            <Route
              path="store/categories"
              element={renderSuspense(<StoreCategories />)}
            />
            <Route
              path="store/products"
              element={renderSuspense(<StoreProducts />)}
            />
            <Route
              path="store/products/add"
              element={renderSuspense(<StoreProductsAdd />)}
            />
            <Route
              path="store/products/:productId/edit"
              element={renderSuspense(<StoreProductsAdd />)}
            />

            <Route path="events" element={renderSuspense(<Events />)} />
            <Route path="events/add" element={renderSuspense(<EventAdd />)} />
            <Route
              path="events/:eventId/edit"
              element={renderSuspense(<EventAdd />)}
            />
          </Route>
        </Routes>
      </SidebarProvider>
    </BlurProvider>
  );
}

export default AdminApp;

import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { ChakraProvider } from "@chakra-ui/react";
import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import enTranslations from "./lang/en/common.json";
import { theme } from "./themes/theme";
import { BrowserRouter } from "react-router-dom";
import AdminApp from "./apps/AdminApp";
import { AuthProvider } from "./context/AuthContext";

i18next.use(initReactI18next).init({
  resources: {
    en: {
      translation: enTranslations,
    },
  },
  lng: localStorage.getItem("lang") || "id",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

const container = document.getElementById("root");

if (container) {
  const root = createRoot(container);

  root.render(
    <React.StrictMode>
      <ChakraProvider theme={theme}>
        <AuthProvider>
          {/* <SocketProvider> */}
          <BrowserRouter>
            <AdminApp />
          </BrowserRouter>
          {/* </SocketProvider> */}
        </AuthProvider>
      </ChakraProvider>
    </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

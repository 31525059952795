import { extendTheme } from "@chakra-ui/react";
import { createBreakpoints } from "@chakra-ui/theme-tools";

const breakpoints = createBreakpoints({
  sm: "30em",
  md: "58em",
  lg: "62em",
  xl: "80em",
  "2xl": "96em",
});

export const theme = extendTheme({
  fonts: {
    heading: "'Patua One', cursive",
    body: "Lato",
  },
  breakpoints,
  colors: {
    primary: {
      300: "#f98d1d",
      400: "#f77c29",
      500: "#f55b3e",
    },
    secondary: {
      500: "#41bcb5",
    },
    secondaryText2: "#827D7D",
    textPrimary: "#0D2C23",
    textSecond: "rgba(13, 44, 35, 0.5)",
    text4: "#B3BEC5",
    gold: "#ffbe2e",
    danger: {
      50: "#FFF1EC",
      400: "#ED535A",
      500: "#E21F39",
    },
    textColorMain: "#01263F",
    success: "#54BF5E",
    warning: "#FE9534",
  },
  styles: {
    global: () => ({
      "html, body": {
        background:
          "linear-gradient(0deg, rgba(236, 249, 246, 0.2), rgba(236, 249, 246, 0.2)), linear-gradient(0deg, #FFFFFF, #FFFFFF), #FFFFFF;",
      },
      formLabel: {
        fontSize: "12px",
      },
      a: {
        color: "primary.500",
      },
    }),
  },
});

import { Icon } from "@chakra-ui/react";
import { HiDocumentDuplicate } from "react-icons/hi";
import { FcDoughnutChart, FcTemplate, FcDocument } from "react-icons/fc";
import { BiMessageDots } from "react-icons/bi";

export type SidebarNav = {
  title: string;
  icon: JSX.Element;
  href: string;
};

export type SidebarNavGroup = {
  title?: string;
  navs: SidebarNav[];
};

export const sidebarNavGroups: SidebarNavGroup[] = [
  {
    navs: [
      {
        title: "Dashboard",
        icon: <Icon as={FcDoughnutChart} w={5} h={5} />,
        href: "/",
      },
    ],
  },
  {
    title: "Content",
    navs: [
      {
        title: "Gallery",
        icon: <Icon as={FcTemplate} color={"red.500"} w={5} h={5} />,
        href: "/content/gallery",
      },
      {
        title: "Testimony",
        icon: <Icon as={BiMessageDots} color={"red.500"} w={5} h={5} />,
        href: "/content/testimonies",
      },
    ],
  },
  {
    title: "Blog",
    navs: [
      {
        title: "Blog Category",
        icon: (
          <Icon as={HiDocumentDuplicate} color={"orange.500"} w={5} h={5} />
        ),
        href: "/blog/categories",
      },
      {
        title: "Blog Post",
        icon: <Icon as={FcDocument} color={"red.500"} w={5} h={5} />,
        href: "/blog/posts",
      },
    ],
  },
];
